import ApiBase from "./api_base";

const relativePath = "adi";
const authPath = "/api/v1/auth";

export default new ApiBase("v1", { relativePath, authPath })
  .resource("/tax_documents/waiting_dedicated_inputs", { only: ["create"] })
  .resource("/tax_documents/waiting_worker_inputs", { only: ["create"] })
  .resources("/preferences", { only: ["index"] })
  .resources("/document_classification_conditions_commons", { only: ["index", "create", "update"] })
  .resources("/document_classification_condition_by_suppliers", { only: ["index", "create", "update"] })
  .post("/document_classification_condition_by_suppliers/{id}/swap")
  .post("/document_classification_conditions/{id}/swap")
  .destroy("/document_classification_conditions/{id}/destroy")
  .put("/document_classification_conditions/{id}/enable")
  .put("/document_classification_conditions/{id}/disable")
  .put("/preferences/update")

  .build();
